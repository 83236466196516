<template>
  <!-- 首页 -->
  <el-scrollbar>
    <div class="home-page x-x" v-loading="loading" v-show="curPage == 'homePage'">
      <div class="main">
        <!-- 快捷菜单 -->
        <div class="main-item-box shortcutMenu">
          <div class="header x-bc">
            <div class="title">快速发起</div>
            <div
              class="customizeMenuBtn el-icon-edit pointer"
              @click="customshortcutMenu"
            >
              自定义
            </div>
          </div>
          <div class="menuList">
            <el-scrollbar>
              <div class="content">
                <div
                  class="menuItem"
                  v-for="(item, index) in shortcutMenuList"
                  :key="index"
                >
                  <div class="icon pointer" @click="$router.push(item.routerPath)">
                    <!-- {{ item }} -->
                    <svg-icon
                      v-if="item.menuImageUrl"
                      :icon-class="item.menuImageUrl || ''"
                      style="height: 38px; width: 38px"
                    />
                    <svg-icon
                      v-else
                      icon-class="menu-diy-icon"
                      style="height: 38px; width: 38px"
                    />
                  </div>
                  <div class="name">{{ item.meta.title }}</div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <!-- 待办业务 -->
        <div class="main-item-box needHandleBusiness" v-loading="businessLoading">
          <div class="header x-bc">
            <div class="businessTabs x-x">
              <div
                class="tab pointer"
                v-for="(item, index) in needHandleBusinessList"
                :key="index"
                :class="{ active: curBusinessIndex === index }"
                @click="curBusinessChange(index)"
              >
                <div class="name">{{ item.businessName }}</div>
                <div class="totalQuantity" v-show="item.total">
                  {{ item.total }}
                </div>
              </div>
            </div>
            <i
              :class="[
                refreshBtn == 'business'
                  ? 'refreshIcon el-icon-refresh-right rotateIcon'
                  : 'refreshIcon el-icon-refresh-right',
              ]"
              @click="refreshClick('business')"
            ></i>
          </div>
          <div class="content x-x">
            <LeftRightSwiperScroll
              v-if="true"
              :swiperList="needHandleBusinessList[curBusinessIndex]"
              :dataChange="curBusinessIndex"
              ref="LeftRightSwiperScroll"
            >
              <div
                class="needHandleBusinessItem"
                v-for="(item, index) in needHandleBusinessList[curBusinessIndex]
                  .businessData"
                :key="index"
                @click="handleNeedHandleBusiness(item.routeUrl)"
              >
                <div class="x-f warp">
                  <div class="left-icon">
                    <img
                      :src="
                        needHandleBusinessListIcon[curBusinessIndex].businessIcon[index]
                      "
                      alt="加载失败"
                    />
                  </div>
                  <div class="right-info">
                    <div class="quantity">{{ item.qry }}</div>
                    <div class="label">{{ item.billTypeName }}</div>
                  </div>
                </div>
              </div>
            </LeftRightSwiperScroll>
          </div>
        </div>
        <!-- 营业数据 -->
        <div class="main-item-box businessData" v-loading="tradeLoading">
          <div class="header x-bc">
            <div class="title">营业数据</div>
            <div class="search x-f">
              <!-- 快捷时间 -->
              <el-radio-group
                v-model="tradeQuery.filterTime"
                size="mini"
                @input="getTradeData"
              >
                <el-radio-button label="today">今天</el-radio-button>
                <el-radio-button label="yesterday">昨天</el-radio-button>
                <el-radio-button label="thisWeek">本周</el-radio-button>
                <el-radio-button label="thisMonth">本月</el-radio-button>
                <el-radio-button label="lastMonth">上月</el-radio-button>
              </el-radio-group>
              <!-- <el-radio-group
                v-model="tradeQuery.filterTime"
                @input="getTradeData"
                size="mini"
              >
                <el-radio-button label="today">今天</el-radio-button>
                <el-radio-button label="yesterday">昨天</el-radio-button>
                <el-radio-button label="thisWeek">本周</el-radio-button>
                <el-radio-button label="thisMonth">本月</el-radio-button>
                <el-radio-button label="thisYear">今年</el-radio-button>
              </el-radio-group> -->
              <!-- <timeRange
                format="datetimerange"
                default-value="day"
                size="mini"
                v-model="tradeQuery.datetime"
                @change="getTradeData"
                :data="['day', 'yesterday', 'week', 'month', 'lastMonth']"
              /> -->
              <i
                :class="[
                  refreshBtn == 'trade'
                    ? 'refreshIcon el-icon-refresh-right rotateIcon'
                    : 'refreshIcon el-icon-refresh-right',
                ]"
                @click="refreshClick('trade')"
              ></i>
            </div>
          </div>
          <div class="content">
            <div class="data-content">
              <div class="left x-bc">
                <div class="y-f w50">
                  <div class="num">
                    {{ isBusiness ? "****" : businessData.businessMoney || 0 }}
                  </div>
                  <div>营业金额(元)</div>
                  <div>
                    (<span class="blueColor">{{ businessData.businessQty || 0 }}</span
                    >笔)
                  </div>
                </div>
                <div class="line"></div>
                <div class="w50 padd15">
                  <div class="x-bc h22">
                    <div>销售金额：</div>
                    <div>
                      <span class="blueColor">{{
                        isBusiness ? "****" : businessData.saleMoney || 0
                      }}</span
                      >元，<span class="blueColor">{{ businessData.saleQty || 0 }}</span
                      >笔
                    </div>
                  </div>
                  <div class="x-bc h22">
                    <div>充值金额：</div>
                    <div>
                      <span class="blueColor">{{
                        isBusiness ? "****" : businessData.rechargeMoney || 0
                      }}</span
                      >元，<span class="blueColor">{{
                        businessData.rechargeQty || 0
                      }}</span
                      >笔
                    </div>
                  </div>
                </div>
              </div>
              <div class="right x-bc">
                <div class="y-f w50">
                  <div class="num">
                    {{ isBusiness ? "****" : businessData.orderMoney || 0 }}
                  </div>
                  <div>订单金额(元)</div>
                  <div>
                    (<span class="blueColor">{{ businessData.orderQty || 0 }}</span
                    >笔)
                  </div>
                </div>
                <div class="line"></div>
                <div class="w50 padd15">
                  <div class="x-bc h22">
                    <div>门店预定金额：</div>
                    <div>
                      <span class="blueColor">{{
                        isBusiness ? "****" : businessData.shopReserveMoney || 0
                      }}</span
                      >元，<span class="blueColor">{{
                        businessData.shopReserveQty || 0
                      }}</span
                      >笔
                    </div>
                  </div>
                  <div class="x-bc h22">
                    <div>自营商城金额：</div>
                    <div>
                      <span class="blueColor">{{
                        isBusiness ? "****" : businessData.mallMoney || 0
                      }}</span
                      >元，<span class="blueColor">{{ businessData.mallQty || 0 }}</span
                      >笔
                    </div>
                  </div>
                  <div class="x-bc h22">
                    <div>美团金额：</div>
                    <div>
                      <span class="blueColor">{{
                        isBusiness ? "****" : businessData.meituanMoney || 0
                      }}</span
                      >元，<span class="blueColor">{{
                        businessData.meituanQty || 0
                      }}</span
                      >笔
                    </div>
                  </div>
                  <div class="x-bc h22">
                    <div>饿了么金额：</div>
                    <div>
                      <span class="blueColor">{{
                        isBusiness ? "****" : businessData.areYouHungryMoney || 0
                      }}</span
                      >元，<span class="blueColor">{{
                        businessData.areYouHungryQty || 0
                      }}</span
                      >笔
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="chart-businessData"
              style="width: 99%; height: 420px; margin-top: 40px"
            ></div>
          </div>
        </div>
        <!-- 数据展示 -->
        <div class="data-show x-bw">
          <div class="main-item-box" style="width: calc(50% - 4px)">
            <div class="header x-bc">
              <div class="title">采购数据</div>
            </div>
            <div class="content">
              <div class="total-list x-bc">
                <div class="item">
                  <div class="num">
                    {{ purchaseData.purOrderMoney || 0 }}
                  </div>
                  <div class="label">
                    {{ `采购订单(${purchaseData.purOrderCount || 0}单)` }}
                  </div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">
                    {{ purchaseData.purInBillMoney || 0 }}
                  </div>
                  <div class="label">
                    {{ `采购入库(${purchaseData.purInBillCount || 0}单)` }}
                  </div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">
                    {{ purchaseData.purBackBillMoney || 0 }}
                  </div>
                  <div class="label">
                    {{ `采购退货(${purchaseData.purBackBillCount || 0}单)` }}
                  </div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">
                    {{ purchaseData.newPartnerCount || 0 }}
                  </div>
                  <div class="label">新增供应商(个)</div>
                </div>
              </div>
              <div
                id="chart-purchase"
                style="width: 99%; height: 420px; margin: 30px 0"
              ></div>
            </div>
          </div>
          <div class="main-item-box" style="width: calc(50% - 4px)">
            <div class="header x-bc">
              <div class="title">销售数据</div>
            </div>
            <div class="content">
              <div class="total-list x-bc">
                <div class="item">
                  <div class="num">
                    {{ saleData.saleOrderMoney || 0 }}
                  </div>
                  <div class="label">
                    {{ `销售订单(${saleData.saleOrderCount || 0}单)` }}
                  </div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">
                    {{ saleData.saleOutBillMoney || 0 }}
                  </div>
                  <div class="label">
                    {{ `销售出库(${saleData.saleOutBillCount || 0}单)` }}
                  </div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">
                    {{ saleData.saleBackBillMoney || 0 }}
                  </div>
                  <div class="label">
                    {{ `销售退货(${saleData.saleBackBillCount || 0}单)` }}
                  </div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">
                    {{ saleData.newPartnerCount || 0 }}
                  </div>
                  <div class="label">新增客户(个)</div>
                </div>
              </div>
              <div
                id="chart-sale"
                style="width: 99%; height: 420px; margin: 30px 0"
              ></div>
            </div>
          </div>
          <div class="main-item-box" style="width: calc(50% - 4px)">
            <div class="header x-bc">
              <div class="title">仓库数据</div>
            </div>
            <div class="content">
              <div class="total-list x-bc">
                <div class="item">
                  <div class="num">
                    {{ storeData.firstQty || 0 }}
                  </div>
                  <div class="label">期初数</div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">{{ storeData.inQty || 0 }}</div>
                  <div class="label">收入数</div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">{{ storeData.outQty || 0 }}</div>
                  <div class="label">发出数</div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">
                    {{ storeData.finalQty || 0 }}
                  </div>
                  <div class="label">期末数</div>
                </div>
              </div>
              <div
                id="chart-store"
                style="width: 99%; height: 420px; margin: 30px 0"
              ></div>
            </div>
          </div>
          <div class="main-item-box" style="width: calc(50% - 4px)">
            <div class="header x-bc">
              <div class="title">生产数据</div>
            </div>
            <div class="content">
              <div class="total-list x-bc">
                <div class="item">
                  <div class="num">
                    {{ produceData.orderQty || 0 }}
                  </div>
                  <div class="label">订货数</div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">
                    {{ produceData.produceQty || 0 }}
                  </div>
                  <div class="label">生产数</div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">
                    {{ produceData.inStoreQty || 0 }}
                  </div>
                  <div class="label">入库数</div>
                </div>
                <div class="line"></div>
                <div class="item">
                  <div class="num">
                    {{ produceData.distributeQty || 0 }}
                  </div>
                  <div class="label">分货数</div>
                </div>
              </div>
              <div
                id="chart-produce"
                style="width: 99%; height: 420px; margin: 30px 0"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="aside">
        <!-- 提醒信息 -->
        <div class="main-item-box reminderInfo" v-if="tipsList.length > 0">
          <div class="header x-f">
            <div class="title">提醒信息</div>
          </div>
          <div class="content infoList">
            <div
              class="infoItem pointer x-b"
              @click="clickTips(item)"
              v-for="(item, index) in tipsList"
              :key="index"
            >
              <div class="eventName x-f">
                <span class="icon">•</span>
                <span class="name">{{ item.sysRemindTypeName }}</span>
              </div>
              <div class="num-icon x-f">
                <span class="num">{{ item.count }}</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- 官方公告 -->
        <div class="main-item-box officialAnnouncement">
          <div class="header x-f">
            <div class="title">产品公告</div>
          </div>
          <div class="content infoList" v-loading="productLoading">
            <div
              class="infoItem pointer x-b"
              v-for="item in curProductList"
              :key="item.noticeId"
              @click="queryNoticeDetail(item.noticeId, '0')"
            >
              <div class="eventName x-f">
                <span class="icon">•</span>
                <span class="name">{{ item.noticeTitle }}</span>
              </div>
              <div class="num-icon x-f">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
          <div
            class="moreBtn x-fc pointer"
            @click="clickMoreProductNotice"
            v-show="isProductMoreBtn"
          >
            {{ productMoreBtn }}
          </div>
        </div>
        <!-- 企业公告 -->
        <div class="main-item-box enterpriseAnnouncement">
          <div class="header x-f">
            <div class="title">企业公告</div>
          </div>
          <div class="content infoList" v-loading="enterpriseLoading">
            <div
              class="infoItem pointer x-b"
              v-for="item in enterpriseNoticeList"
              :key="item.noticeId"
              @click="queryNoticeDetail(item.noticeId, '1')"
            >
              <div class="eventName x-f">
                <span class="icon" style="color: #fdc177">•</span>
                <span class="name">{{ item.noticeTitle }}</span>
              </div>
              <div class="num-icon x-f">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- 帮助中心 -->
        <div class="main-item-box help">
          <div class="header x-f">
            <div class="title">帮助中心</div>
          </div>
          <div class="content">
            <div class="item pointer x-f" v-for="(item, index) in helpList" :key="index">
              <div class="icon">
                <img :src="item.icon" alt="加载失败" />
              </div>
              <div class="label">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="main-item-box help">
          <div class="header x-f" @click="clickAIDialog" style="cursor: pointer">
            <div class="title">隼云AI助手</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业公告详情 -->
    <Notice
      ref="notice"
      :noticeId="curEnterpriseNoticeId"
      :curNoticeType="curNoticeType"
      v-show="curPage == 'notice'"
      @goHome="curPage = 'homePage'"
    />
    <!-- 快速发起管理弹窗 -->
    <RapidInitiation
      :openDialog.sync="openRapidInitiationDialog"
      :allMenuTreeList="allMenuTreeList"
      :shortcutMenuList="shortcutMenuList"
      @saveShortcutMenu="saveShortcutMenu"
    />
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </el-scrollbar>
</template>

<script>
import Dialog from "@/components/Dialog";
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import { mapGetters } from "vuex";
import timeRange from "@/components/tablePage/time-range/index.vue";
import RapidInitiation from "@/views/home-page/components/rapidInitiation.vue"; //快速发起管理弹窗
import LeftRightSwiperScroll from "./components/leftRightSwiperScroll.vue"; //横向滚动组件超出显示箭头按钮
import Notice from "@/views/home-page/components/notice.vue";
import {
  getMenuList,
  getShortcutMenuList,
  handleShortcutMenu,
  getHomeBusinessData, //业务数据(待办业务)
  getHomeOrderTrendData, //营业单据走势图数据
  getHomeTradeProduceData, //营业数据-生产
  getHomeTradePurData, //营业数据-采购
  getHomeTradeSaleData, //营业数据-销售
  getHomeTradeStoreData, //营业数据-仓库
  getHomeTradeData, //营业数据
  getHomeOfficialNotice, //产品公告
  getHomeEnterpriseNotice, //企业公告
  getReminderInformation, //提醒信息统计列表
} from "@/api/homePage"; //接口api
import { getData } from "@/utils/public"; //获取下拉框数据
import { getTimes } from "@/utils/index.js";
import { dataListAPI } from "@/api/system/maintain/param/dataParam";
export default {
  name: "HomePage",
  components: { timeRange, RapidInitiation, LeftRightSwiperScroll, Notice, Dialog },
  //进入路由
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      console.log("别太过分", window.location.href);
    });
  },
  data() {
    return {
      //弹窗配置
      dialogOptions: {
        title: "隼云AI助手",

        width: 600,
        show: false,
        type: "SunYunAI",
      },
      openAIDialog: false, //隼云AI助手弹窗开关

      isBusiness: false, // 业务金额是否隐藏
      tipsList: [],
      openRapidInitiationDialog: false,
      loading: false, //遮罩层
      curPage: "homePage", //当前页面
      //当前刷新按钮
      refreshBtn: "",
      shopList: [], //门店列表
      //全部菜单树形列表(3层数组)
      allMenuTreeList: undefined,
      //全部菜单列表(1层数组)
      allMenuList: [],
      //快速发起菜单列表
      shortcutMenuList: [],
      //快速发起菜单弹窗开关
      //官方公告更多按钮开关
      isMoreBtn: false,
      //待办业务宽度
      contentWidth: 20 * 100,
      //当前待办业务下标
      curBusinessIndex: 0,
      //待办业务数组
      needHandleBusinessList: [
        {
          name: "门店业务",
          totalQuantity: "42",
          children: [
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店订货/待审核",
              quantity: "5",
            },
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店原料订货/待审核",
              quantity: "4",
            },
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店采购/待审核",
              quantity: "2",
            },
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店报废/待审核",
              quantity: "10",
            },
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店调入/待审核",
              quantity: "6",
            },
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店调出/待审核",
              quantity: "10",
            },
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店生产/待审核",
              quantity: "5",
            },
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店领料/待审核",
              quantity: "15",
            },
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店盘点/待审核",
              quantity: "9",
            },
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店订货/待审核",
              quantity: "10",
            },
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店订货/待审核",
              quantity: "11",
            },
            {
              icon: require("@/assets/images/annualsales.png"),
              label: "门店订货/待审核",
              quantity: "12",
            },
          ],
        },
        { name: "采购业务", totalQuantity: "18" },
        { name: "销售业务", totalQuantity: "12" },
        { name: "仓库业务", totalQuantity: "15" },
        { name: "生产业务", totalQuantity: "23" },
      ],
      needHandleBusinessListIcon: [
        {
          businessName: "门店业务",
          businessIcon: [
            require("@/assets/imgs/homePage/db-shop1-icon.svg"),
            require("@/assets/imgs/homePage/db-shop2-icon.svg"),
            require("@/assets/imgs/homePage/db-shop3-icon.svg"),
            require("@/assets/imgs/homePage/db-shop4-icon.svg"),
            require("@/assets/imgs/homePage/db-shop5-icon.svg"),
            require("@/assets/imgs/homePage/db-shop6-icon.svg"),
            require("@/assets/imgs/homePage/db-shop7-icon.svg"),
            require("@/assets/imgs/homePage/db-shop8-icon.svg"),
            require("@/assets/imgs/homePage/db-shop9-icon.svg"),
            require("@/assets/imgs/homePage/db-shop10-icon.svg"),
            require("@/assets/imgs/homePage/db-shop11-icon.svg"),
          ],
        },
        {
          businessName: "采购业务",
          businessIcon: [
            require("@/assets/imgs/homePage/db-purchase1-icon.svg"),
            require("@/assets/imgs/homePage/db-purchase2-icon.svg"),
            require("@/assets/imgs/homePage/db-purchase3-icon.svg"),
          ],
        },
        {
          businessName: "销售业务",
          businessIcon: [
            require("@/assets/imgs/homePage/db-sale1-icon.svg"),
            require("@/assets/imgs/homePage/db-sale2-icon.svg"),
            require("@/assets/imgs/homePage/db-sale3-icon.svg"),
          ],
        },
        {
          businessName: "仓库业务",
          businessIcon: [
            require("@/assets/imgs/homePage/db-store1-icon.svg"),
            require("@/assets/imgs/homePage/db-store2-icon.svg"),
            require("@/assets/imgs/homePage/db-store3-icon.svg"),
            require("@/assets/imgs/homePage/db-store4-icon.svg"),
            require("@/assets/imgs/homePage/db-store5-icon.svg"),
            require("@/assets/imgs/homePage/db-store6-icon.svg"),
            require("@/assets/imgs/homePage/db-store7-icon.svg"),
            require("@/assets/imgs/homePage/db-store8-icon.svg"),
            require("@/assets/imgs/homePage/db-store9-icon.svg"),
          ],
        },
        {
          businessName: "生产业务",
          businessIcon: [
            require("@/assets/imgs/homePage/db-produce1-icon.svg"),
            require("@/assets/imgs/homePage/db-produce2-icon.svg"),
            require("@/assets/imgs/homePage/db-produce3-icon.svg"),
            require("@/assets/imgs/homePage/db-produce4-icon.svg"),
          ],
        },
      ],
      //待办业务图标数组
      needHandleBusinessIconList: [],
      //待办业务图标
      businessIcon: require("@/assets/images/annualsales.png"),
      //待办业务遮罩层
      businessLoading: false,
      //营业数据查询参数
      tradeQuery: {
        shopId: "",
        filterTime: "today",
      },
      //营业数据遮罩层
      tradeLoading: false,
      //营业数据chart
      myBusinessChart: undefined,
      businessData: {},
      //采购数据chart
      myPurchaseChart: undefined,
      purchaseData: {},
      //销售数据chart
      mySaleChart: undefined,
      saleData: {},
      //仓库数据chart
      myStoreChart: undefined,
      storeData: {},
      //生产数据chart
      myProduceChart: undefined,
      produceData: {},
      //产品公告遮罩层
      productLoading: false,
      //产品公告
      productList: [],
      //当前产品公告
      curProductList: [],
      //是否产品公告按钮显示
      isProductMoreBtn: false,
      //产品公告按钮(收起/更多)
      productMoreBtn: "更多",
      //企业公告遮罩层
      enterpriseLoading: false,
      //企业公告数据
      enterpriseNoticeList: [],
      //当前点击的企业公告id
      curEnterpriseNoticeId: undefined,
      curNoticeType: "0", //公告类型(0产品公告1企业公告)
      //帮助中心数组
      helpList: [
        {
          icon: require("@/assets/imgs/homePage/help-new_guide_panel-icon.png"),
          name: "新手攻略",
        },
        {
          icon: require("@/assets/imgs/homePage/help-OnlineHelp-icon.png"),
          name: "在线帮助",
        },
        {
          icon: require("@/assets/imgs/homePage/help-InitializeBoot-icon.png"),
          name: "初始化引导",
        },
        {
          icon: require("@/assets/imgs/homePage/help-flowChart-icon.png"),
          name: "业务流程图",
        },
      ],
      value: undefined,
      employeeId: undefined, //员工id
    };
  },
  computed: {
    ...mapGetters(["userinfo"]),
  },
  async created() {
    //判断herf网页路径是否从收银端过来,进入门店调入页面
    const billId = this.getUrlCode("billId");
    if (billId) {
      this.$router.push({
        name: "MoveInDetail",
        query: {
          billId,
          inStoreId: this.getUrlCode("inStoreId"),
          outStoreId: this.getUrlCode("outStoreId"),
        },
      });
      return;
    }
    try {
      // 获取首页金额隐藏参数
      const { data } = await dataListAPI();
      if (data.system_BUSINESS_DATA_CONFIG_BASIC_23) {
        this.isBusiness = data.system_BUSINESS_DATA_CONFIG_BASIC_23;
      }
      this.loading = true;
      this.employeeId = this.userinfo.userId; //员工id
      this.shopList = await getData("shop"); //获取门店数据
      this.shopList.unshift({
        shopName: "全部",
        shopId: "",
      });
      this.getShortcutMenu();
      this.getBusinessList(); //业务列表
      await this.getTradeData(); //营业数据
      this.getProductNotice(); //产品公告
      this.getEnterpriseNotice(); //企业公告
      this.getReminderInformationFun(); //提醒信息统计列表
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  },
  methods: {
    clickAIDialog() {
      this.dialogOptions.show = true;
    },
    ceil(number) {
      if (number < 1) {
        return 0.2;
      } else {
        let numLength = parseInt(number).toString().length - 2;
        let zoomIndex = 10 ** numLength;
        return Math.ceil(number / zoomIndex) * zoomIndex;
      }
    },

    clickTips(item) {
      this.$router.push({ path: item.sysMenuPath });
    },
    //初始化业务chart
    initChart({ myChart, data, chartId, legend, type, yAxisNames, showUnit }) {
      if (this[myChart]) {
        this[myChart].dispose(); // 销毁之前的实例
      }
      const chartContainer = document.getElementById(chartId);
      this[myChart] = this.$echarts.init(chartContainer);
      let noNumberMax = Math.ceil(Math.max.apply(null, this[data].noNumberArr) / 10) * 10;
      noNumberMax = noNumberMax >= 10 ? noNumberMax : 10;
      let payMoneyMax = Math.ceil(Math.max.apply(null, this[data].payMoneyArr) / 10) * 10;
      payMoneyMax = payMoneyMax >= 10 ? payMoneyMax : 10;
      let noNumberInterval = (noNumberMax - 0) / 5;
      let payMoneyInterval = (payMoneyMax - 0) / 5;
      // 设置图表配置
      const option = {
        tooltip: {
          trigger: "axis", // 提示框触发类型
          axisPointer: {
            crossStyle: {
              color: "#73d13d", // 十字准星的颜色
            },
          },
          formatter: (params) => {
            return `${params[0].name}<br>${params[0].marker}${legend[0].name}:${
              params[0].data
            }${showUnit ? "(元)" : ""}<br>${params[1].marker}${legend[1].name}:${
              params[1].data
            }${showUnit ? "(笔)" : ""}`; // 提示框内容格式化
          },
        },
        legend: {
          data: legend.map((x) => x.name), // 图例名称
          itemWidth: type == "line" ? 30 : 25, // 设置图例项的宽度，增加线的长度
          itemHeight: type == "line" ? 0 : 14, // 设置图例项的高度为0，折线图隐藏中间的点
          textStyle: {
            fontSize: 14, //文本大小
            color: "#535353", // 图例文本颜色
          },
        },
        grid: {
          left: 10, // 图表左边空白
          right: 20, // 图表右边空白
          bottom: "5%", // 图表底部空白
          containLabel: true, // 包含坐标轴的刻度标签
        },
        xAxis: [
          {
            type: "category", // x 轴类型为类目轴
            data: this[data].dateStrArr, // x 轴数据
            axisTick: {
              show: false, // 不显示刻度线
            },
            axisLine: {
              lineStyle: {
                color: "#dddddd", // x 轴线颜色
              },
            },
            boundaryGap: type == "line" ? false : true, // 两端空白策略，false 表示留白
            axisLabel: {
              color: "#535353", // x 轴文本的颜色
              fontSize: 14, // x 轴标签字体大小
            },
          },
        ],
        yAxis: [
          {
            type: "value", // y 轴类型为数值轴
            name: yAxisNames[0], // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            min: 0,
            max: payMoneyMax,
            interval: payMoneyInterval,
            nameTextStyle: {
              color: "#535353", // 文本的颜色
            },
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 元', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
              color: "#535353", // x 轴文本的颜色
            },
          },
          {
            type: "value", // y 轴类型为数值轴
            name: yAxisNames[1], // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            min: 0,
            max: noNumberMax,
            interval: noNumberInterval,
            nameTextStyle: {
              color: "#535353", // 文本的颜色
            },
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 笔', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
              color: "#535353",
            },
          },
        ],
        series: [
          {
            name: legend[0].name, // 系列名称
            type, // 系列类型为折线图
            smooth: true,
            data: this[data].payMoneyArr, // 系列数据
            yAxisIndex: 0, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            barMaxWidth: 25, // 设置柱子最大粗细
            itemStyle: {
              color: legend[0].lineColor, // 数据项样式
            },
            lineStyle: {
              color: legend[0].lineColor, // 折线样式
            },
          },
          {
            name: legend[1].name, // 系列名称
            type, // 系列类型为折线图
            smooth: true,
            data: this[data].noNumberArr, // 系列数据
            yAxisIndex: 1, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            barMaxWidth: 25, // 设置柱子最大粗细
            itemStyle: {
              color: legend[1].lineColor, // 数据项样式
            },
            lineStyle: {
              color: legend[1].lineColor, // 折线样式
            },
          },
        ],
      };
      // 设置图表配置
      this[myChart].setOption(option);
      // 监听窗口变化，调整图表大小
      window.addEventListener("resize", () => {
        this[myChart].resize();
      });
    },
    //提醒信息统计列表
    async getReminderInformationFun() {
      //用户id
      let res = await getReminderInformation(); //提醒信息统计列表
      this.tipsList = res.data.filter((item) => item.count);
    },
    //切换待办
    curBusinessChange(index) {
      this.curBusinessIndex = index;
    },
    //切割herf路径
    getUrlCode(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            decodeURIComponent(window.location.href)
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    //刷新按钮
    refreshClick(type) {
      this.refreshBtn = type;
      if (type == "business") this.getBusinessList(); //业务列表
      if (type == "trade") this.getTradeData(); //营业数据
      setTimeout(() => {
        this.refreshBtn = "";
      }, 1000);
    },
    //获取快捷菜单
    async getShortcutMenu() {
      //用户id
      const res = await getShortcutMenuList(this.employeeId);
      // console.log(res)
      this.shortcutMenuList = [];
      res.forEach((item) => {
        //解析JSON
        let customContent = JSON.parse(item.customContent);
        this.shortcutMenuList.push({
          ...customContent,
          sortNo: item.sortNo,
        });
      });
    },
    //自定义快捷菜单
    async customshortcutMenu() {
      //用户id
      let res = await getMenuList(this.employeeId); //获取菜单列表
      this.allMenuTreeList = res.data;
      this.openRapidInitiationDialog = true;
    },

    //保存快速发起菜单
    async saveShortcutMenu(list) {
      try {
        if (list?.length === 0) return;
        let menuList = [];
        for (let i = 0; i < list.length; i++) {
          console.log("进来了嘛!", i);
          let JSONContent = JSON.stringify(list[i]);
          menuList.push({
            customContent: JSONContent,
            sortNo: i,
            employeeId: this.employeeId,
          });
        }
        await handleShortcutMenu(menuList);
        this.$message.success("添加快速发起菜单成功");
        this.getShortcutMenu();
      } catch {}
    },
    //获取待办业务列表
    async getBusinessList() {
      this.businessLoading = true;
      try {
        const { data } = await getHomeBusinessData();
        this.needHandleBusinessList = data;
        this.businessLoading = false;
      } catch {
        this.businessLoading = false;
      }
    },
    //操作待办业务
    handleNeedHandleBusiness(routeUrl) {
      this.$router.push(`${routeUrl}?billStatus=0`);
    },
    //获取营业数据
    async getTradeData(val) {
      return new Promise(async (resolve, reject) => {
        try {
          this.tradeLoading = true;
          let datetime = getTimes({
            type: this.tradeQuery.filterTime,
            format: "datetimerange",
          }).filter((ite) => typeof ite !== "undefined");
          this.tradeQuery.beginBillDate = datetime[0];
          this.tradeQuery.endBillDate = datetime[1];
          // 并发执行所有请求
          const promises = [
            getHomeOrderTrendData(this.tradeQuery),
            getHomeTradeData(this.tradeQuery),
            getHomeTradePurData(this.tradeQuery), //营业数据-采购
            getHomeTradeSaleData(this.tradeQuery), //营业数据-销售
            getHomeTradeStoreData(this.tradeQuery), //营业数据-仓库
            getHomeTradeProduceData(this.tradeQuery), //营业数据-生产
          ];

          let results = await Promise.all(promises);

          if (this.isBusiness) {
            this.businessData = {
              dateStrArr: [],
              noNumberArr: results[0].data.map((item) => item.noNumber) || [],
              payMoneyArr: [],
            };
          } else {
            this.businessData = {
              ...results[1].data,
              dateStrArr: results[0].data.map((item) => item.dateStr) || [],
              noNumberArr: results[0].data.map((item) => item.noNumber) || [],
              payMoneyArr: results[0].data.map((item) => item.payMoney) || [],
            };
          }
          this.purchaseData = {
            ...results[2].data,
            dateStrArr:
              results[2].data.homeBillTrendRespList.map((item) => item.billDateStr) || [],
            payMoneyArr:
              results[2].data.homeBillTrendRespList.map((item) => item.number1) || [],
            noNumberArr:
              results[2].data.homeBillTrendRespList.map((item) => item.number2) || [],
          };

          this.saleData = {
            ...results[3].data,
            dateStrArr:
              results[3].data.homeBillTrendRespList.map((item) => item.billDateStr) || [],
            payMoneyArr:
              results[3].data.homeBillTrendRespList.map((item) => item.number1) || [],
            noNumberArr:
              results[3].data.homeBillTrendRespList.map((item) => item.number2) || [],
          };

          this.storeData = {
            ...results[4].data,
            dateStrArr:
              results[4].data.homeBillTrendRespList.map((item) => item.billDateStr) || [],
            payMoneyArr:
              results[4].data.homeBillTrendRespList.map((item) => item.number1) || [],
            noNumberArr:
              results[4].data.homeBillTrendRespList.map((item) => item.number2) || [],
          };

          this.produceData = {
            ...results[5].data,
            dateStrArr:
              results[5].data.homeBillTrendRespList.map((item) => item.billDateStr) || [],
            payMoneyArr:
              results[5].data.homeBillTrendRespList.map((item) => item.number1) || [],
            noNumberArr:
              results[5].data.homeBillTrendRespList.map((item) => item.number2) || [],
          };

          this.initChart({
            myChart: "myBusinessChart",
            data: "businessData",
            chartId: "chart-businessData",
            type: "line",
            legend: [
              { name: "营业额", lineColor: "#FF9901" },
              { name: "订单数", lineColor: "#409EFF" },
            ],
            yAxisNames: ["营业额(元)", "订单数(笔)"],
            showUnit: true,
          });
          this.initChart({
            myChart: "myPurchaseChart",
            data: "purchaseData",
            chartId: "chart-purchase",
            type: "bar",
            legend: [
              { name: "订单额", lineColor: "#FF9901" },
              { name: "订单数", lineColor: "#9477F9" },
            ],
            yAxisNames: ["订单额(元)", "订单数(笔)"],
            showUnit: true,
          });
          this.initChart({
            myChart: "mySaleChart",
            data: "saleData",
            chartId: "chart-sale",
            type: "bar",
            legend: [
              { name: "订单额", lineColor: "#FF9901" },
              { name: "订单数", lineColor: "#73D13D" },
            ],
            yAxisNames: ["订单额(元)", "订单数(笔)"],
            showUnit: true,
          });
          this.initChart({
            myChart: "myStoreChart",
            data: "storeData",
            chartId: "chart-store",
            type: "bar",
            legend: [
              { name: "收入数", lineColor: "#409EFF" },
              { name: "发出数", lineColor: "#FF9901" },
            ],
            yAxisNames: ["收入数", "发出数"],
          });
          this.initChart({
            myChart: "myProduceChart",
            data: "produceData",
            chartId: "chart-produce",
            type: "bar",
            legend: [
              { name: "订货数", lineColor: "#FF9901" },
              { name: "分货数", lineColor: "#9477F9" },
            ],
            yAxisNames: ["订货数", "分货数"],
          });
          this.tradeLoading = false;
          resolve();
        } catch {
          this.tradeLoading = false;
          reject();
        }
      });
    },
    //获取产品公告
    async getProductNotice() {
      this.productLoading = true;
      try {
        const { rows } = await getHomeOfficialNotice();
        this.productList = rows;
        this.isProductMoreBtn = rows.length > 3 ? true : false;
        if (rows.length > 3) {
          this.curProductList = rows.slice(0, 3);
        } else this.curProductList = rows;
        this.productLoading = false;
      } catch {
        this.productLoading = false;
      }
    },
    //点击更改产品公告
    clickMoreProductNotice() {
      if (this.productMoreBtn == "更多") {
        this.curProductList = this.productList;
      } else {
        this.curProductList = this.productList.slice(0, 3);
      }
      this.productMoreBtn = this.productMoreBtn == "更多" ? "收起" : "更多";
    },
    //获取企业公告
    async getEnterpriseNotice() {
      this.enterpriseLoading = true;
      try {
        const { rows } = await getHomeEnterpriseNotice();
        this.enterpriseNoticeList = rows;
        this.enterpriseLoading = false;
      } catch {
        this.enterpriseLoading = false;
      }
    },
    //查看公告详情
    queryNoticeDetail(noticeId, curNoticeType) {
      this.curEnterpriseNoticeId = noticeId;
      this.curPage = "notice";
      this.curNoticeType = curNoticeType;
      this.$refs.notice.getNoticeDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
//滚动条
.el-scrollbar {
  height: calc(100vh - 85px);
  width: 100%;
  min-width: 1300px;
  overflow: hidden;

  // 侧边滚动条显示
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  ::v-deep .el-scrollbar__view {
    height: 100%;
  }
  .home-page {
    background-color: #f2f2f2;
    padding: 8px 10px;
    font-size: 14px;
  }

  .main-item-box {
    background-color: #fff;
    margin-bottom: 8px;
    padding: 5px 30px;
  }
  //刷新图标动态
  .rotateIcon {
    transform: rotate(360deg);
    transition: all 1s;
  }

  .header {
    height: 40px;
    font-size: 14px;
    .title {
      color: #000;
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }
    .customizeMenuBtn {
      color: #8d8d8d;
    }
    // 待办业务
    .businessTabs {
      .tab {
        position: relative;
        width: 60px;
        margin-right: 30px;
        height: 28px;
        font-size: 14px;
        font-weight: bold;
        &.active {
          border-bottom: 1px solid #409eff;
          color: #409eff;
        }
        .totalQuantity {
          position: absolute;
          padding: 2.5px 5px;
          top: -5px;
          right: -13px;
          background-color: #ff1919;
          color: #fff;
          border-radius: 15px;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }
    //销售排行
    .salesRankingTabs {
      font-weight: bold;
      .tab {
        cursor: pointer;
        &.active {
          color: #409eff;
        }
      }
      .line {
        width: 1px;
        background-color: #d2d2d2;
        margin: 0 20px;
      }
    }
    //刷新图标
    .refreshIcon {
      color: #8d8d8d;
      margin-left: 15px;
      font-size: 20px;
      cursor: pointer;
    }
    // 搜索
    .search {
      .el-select,
      .el-date-picker,
      .el-radio-group {
        margin: 0 5px;
      }
      ::v-deep .el-range-editor.el-input__inner {
        width: 380px;
      }
      // 快捷时间
      .el-radio-group {
        width: 260px;
        ::v-deep .el-radio-button {
          width: 20% !important;
        }
        ::v-deep .el-radio-button__inner {
          width: 100% !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          text-align: center;
        }
      }
    }
  }
  .main {
    width: 80%;
    //快速发起菜单
    .shortcutMenu {
      .menuList {
        height: 120px;
        //滚动条
        .el-scrollbar {
          height: 100%;
          overflow: hidden;
          // 侧边滚动条显示
          ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
          }
          ::v-deep .el-scrollbar__bar.is-vertical {
            opacity: 1;
          }
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          .menuItem {
            width: 110px;
            padding-top: 11px;
            height: 80px;
            .icon {
              width: 108px;
              display: table-cell;
              vertical-align: middle;
              text-align: center;
              img {
                width: 32px;
                height: 32px;
              }
            }
            .name {
              width: 100%;
              height: 20px;
              line-height: 20px;
              text-align: center;
            }
          }
        }
      }
    }

    //待办业务
    .needHandleBusiness {
      .content {
        margin: 10px 0;
        width: 100%;
        .needHandleBusinessItem {
          width: 190px;
          height: 86px;
          background-color: #f3f9ff;
          border-radius: 4px;
          margin-right: 5px;
          display: inline-block;
          cursor: pointer;
          .warp {
            height: 100%;
          }
          .left-icon {
            width: 27px;
            height: 29px;
            margin: 0 14px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .right-info {
            .quantity {
              font-size: 16px;
            }
          }
        }
      }
    }
    //营业数据
    .businessData {
      .content {
        margin: 15px 0;
        .blueColor {
          color: #409eff;
        }
        .data-content {
          width: 100%;
          height: 110px;
          display: flex;
          justify-content: space-between;
          .left,
          .right {
            background-color: #f7f8f9;
            width: calc(50% - 10px);
            .num {
              color: #409eff;
              font-size: 25px;
            }
            .w50 {
              width: calc(50% - 1px);
            }
            .h22 {
              height: 22px;
              line-height: 22px;
            }
            .line {
              height: 60px;
              width: 1px;
              background-color: #e5e5e5;
            }
          }
        }
      }
    }
    .data-show {
      .total-list {
        height: 60px;
        .item {
          width: calc(25% - 1px);
          text-align: center;
          .num {
            font-size: 20px;
          }
          .label {
            color: #535353;
          }
        }
        .line {
          height: 30px;
          width: 1px;
          background-color: #e5e5e5;
        }
      }
    }

    //会员数据
    .vipData {
      width: calc(70% - 8px);
      .vipDataSum {
        height: 280px;
        margin: 20px 0;
        .left-vipTotal {
          width: 40%;
          background-color: #f7f8f9;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .num {
            font-size: 35px;
            line-height: 40px;
            margin-bottom: 16px;
          }
        }
        .vipInfoSum-list {
          width: calc(60% - 10px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .vipInfo-item {
            width: 100%;
            height: calc(50% - 4px);
            background-color: #f7f8f9;
            padding-left: 15%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .num {
              font-size: 18px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    //当月目标
    .curMonthGoal {
      width: 30%;
      .target {
        height: 348px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .salesProportionChart {
          height: 70%;
          .chart {
            position: relative;
            width: 160px;
            height: 160px;
            // 进度条底色
            .el-progress ::v-deep path:first-child {
              stroke: #40b5ff;
            }
            .tag {
              font-size: 14px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, 40%);
            }
          }
        }

        .targetAmount {
          height: 30%;
          .Amount {
            width: calc(50% - 1px);
            text-align: center;
          }
          .line {
            width: 1px;
            height: 25px;
            background-color: #dcdcdc;
          }
        }
      }
    }
    // 销售排行
    .salesRanking {
      .content {
        margin: 30px 0;
        min-height: 400px;
        ::v-deep .el-progress-bar__outer {
          border-radius: 0;
        }
        ::v-deep .el-progress-bar__inner {
          border-radius: 0px; // 双边圆角
        }
        .saleItem {
          margin: 18px 0;
          .salesData {
            height: 25px;
            .data-list {
              width: 60%;
              .data-item {
                width: 25%;
                text-align: right;
              }
            }
          }
        }
      }
      .emptyState {
        width: 90%;
        margin: 30px auto;
        min-height: 400px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .aside {
    width: calc(20% - 8px);
    margin-left: 8px;
    //提醒信息//官方公告/企业公告
    .reminderInfo,
    .officialAnnouncement,
    .enterpriseAnnouncement {
      .content {
        margin: 5px 0 10px;
        .infoItem {
          height: 35px;
          &:hover {
            background-color: #f5f5f5;
          }
          .eventName {
            .icon {
              font-size: 25px;
              color: #409eff;
              margin-right: 5px;
            }
          }
          .num-icon {
            margin-left: 5px;
            color: #999;
            .num {
              color: #ff5a6a;
            }
          }
        }
      }
    }
    //官方公告
    .officialAnnouncement {
      .content {
        margin: 5px 10px 10px;
      }
      .moreBtn {
        border-top: 1px solid #eeeeee;
        height: 45px;
        color: #409eff;
      }
    }
    //帮助中心
    .help {
      .content {
        .item {
          height: 40px;
          &:hover {
            color: #409eff;
          }
        }
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 5px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.AIDialog {
  ::v-deep.el-dialog__body {
    padding: 0 !important;
  }
  ::v-deep.ai-content {
    height: 500px;
    background: url(~@/assets/fixedImages/AIbg.png) no-repeat 100% 100%;
  }
}
</style>
