var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.curPage == "homePage",
              expression: "curPage == 'homePage'",
            },
          ],
          staticClass: "home-page x-x",
        },
        [
          _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "main-item-box shortcutMenu" }, [
              _c("div", { staticClass: "header x-bc" }, [
                _c("div", { staticClass: "title" }, [_vm._v("快速发起")]),
                _c(
                  "div",
                  {
                    staticClass: "customizeMenuBtn el-icon-edit pointer",
                    on: { click: _vm.customshortcutMenu },
                  },
                  [_vm._v(" 自定义 ")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "menuList" },
                [
                  _c("el-scrollbar", [
                    _c(
                      "div",
                      { staticClass: "content" },
                      _vm._l(_vm.shortcutMenuList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "menuItem" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "icon pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push(item.routerPath)
                                  },
                                },
                              },
                              [
                                item.menuImageUrl
                                  ? _c("svg-icon", {
                                      staticStyle: {
                                        height: "38px",
                                        width: "38px",
                                      },
                                      attrs: {
                                        "icon-class": item.menuImageUrl || "",
                                      },
                                    })
                                  : _c("svg-icon", {
                                      staticStyle: {
                                        height: "38px",
                                        width: "38px",
                                      },
                                      attrs: { "icon-class": "menu-diy-icon" },
                                    }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.meta.title)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.businessLoading,
                    expression: "businessLoading",
                  },
                ],
                staticClass: "main-item-box needHandleBusiness",
              },
              [
                _c("div", { staticClass: "header x-bc" }, [
                  _c(
                    "div",
                    { staticClass: "businessTabs x-x" },
                    _vm._l(_vm.needHandleBusinessList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "tab pointer",
                          class: { active: _vm.curBusinessIndex === index },
                          on: {
                            click: function ($event) {
                              return _vm.curBusinessChange(index)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.businessName)),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.total,
                                  expression: "item.total",
                                },
                              ],
                              staticClass: "totalQuantity",
                            },
                            [_vm._v(" " + _vm._s(item.total) + " ")]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("i", {
                    class: [
                      _vm.refreshBtn == "business"
                        ? "refreshIcon el-icon-refresh-right rotateIcon"
                        : "refreshIcon el-icon-refresh-right",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.refreshClick("business")
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "content x-x" },
                  [
                    true
                      ? _c(
                          "LeftRightSwiperScroll",
                          {
                            ref: "LeftRightSwiperScroll",
                            attrs: {
                              swiperList:
                                _vm.needHandleBusinessList[
                                  _vm.curBusinessIndex
                                ],
                              dataChange: _vm.curBusinessIndex,
                            },
                          },
                          _vm._l(
                            _vm.needHandleBusinessList[_vm.curBusinessIndex]
                              .businessData,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "needHandleBusinessItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleNeedHandleBusiness(
                                        item.routeUrl
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "x-f warp" }, [
                                    _c("div", { staticClass: "left-icon" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.needHandleBusinessListIcon[
                                            _vm.curBusinessIndex
                                          ].businessIcon[index],
                                          alt: "加载失败",
                                        },
                                      }),
                                    ]),
                                    _c("div", { staticClass: "right-info" }, [
                                      _c("div", { staticClass: "quantity" }, [
                                        _vm._v(_vm._s(item.qry)),
                                      ]),
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v(_vm._s(item.billTypeName)),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tradeLoading,
                    expression: "tradeLoading",
                  },
                ],
                staticClass: "main-item-box businessData",
              },
              [
                _c("div", { staticClass: "header x-bc" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("营业数据")]),
                  _c(
                    "div",
                    { staticClass: "search x-f" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { input: _vm.getTradeData },
                          model: {
                            value: _vm.tradeQuery.filterTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.tradeQuery, "filterTime", $$v)
                            },
                            expression: "tradeQuery.filterTime",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "today" } }, [
                            _vm._v("今天"),
                          ]),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "yesterday" } },
                            [_vm._v("昨天")]
                          ),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "thisWeek" } },
                            [_vm._v("本周")]
                          ),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "thisMonth" } },
                            [_vm._v("本月")]
                          ),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "lastMonth" } },
                            [_vm._v("上月")]
                          ),
                        ],
                        1
                      ),
                      _c("i", {
                        class: [
                          _vm.refreshBtn == "trade"
                            ? "refreshIcon el-icon-refresh-right rotateIcon"
                            : "refreshIcon el-icon-refresh-right",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.refreshClick("trade")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "data-content" }, [
                    _c("div", { staticClass: "left x-bc" }, [
                      _c("div", { staticClass: "y-f w50" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isBusiness
                                  ? "****"
                                  : _vm.businessData.businessMoney || 0
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [_vm._v("营业金额(元)")]),
                        _c("div", [
                          _vm._v(" ("),
                          _c("span", { staticClass: "blueColor" }, [
                            _vm._v(_vm._s(_vm.businessData.businessQty || 0)),
                          ]),
                          _vm._v("笔) "),
                        ]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "w50 padd15" }, [
                        _c("div", { staticClass: "x-bc h22" }, [
                          _c("div", [_vm._v("销售金额：")]),
                          _c("div", [
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.isBusiness
                                    ? "****"
                                    : _vm.businessData.saleMoney || 0
                                )
                              ),
                            ]),
                            _vm._v("元，"),
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(_vm._s(_vm.businessData.saleQty || 0)),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                        _c("div", { staticClass: "x-bc h22" }, [
                          _c("div", [_vm._v("充值金额：")]),
                          _c("div", [
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.isBusiness
                                    ? "****"
                                    : _vm.businessData.rechargeMoney || 0
                                )
                              ),
                            ]),
                            _vm._v("元，"),
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(_vm._s(_vm.businessData.rechargeQty || 0)),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "right x-bc" }, [
                      _c("div", { staticClass: "y-f w50" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isBusiness
                                  ? "****"
                                  : _vm.businessData.orderMoney || 0
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [_vm._v("订单金额(元)")]),
                        _c("div", [
                          _vm._v(" ("),
                          _c("span", { staticClass: "blueColor" }, [
                            _vm._v(_vm._s(_vm.businessData.orderQty || 0)),
                          ]),
                          _vm._v("笔) "),
                        ]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "w50 padd15" }, [
                        _c("div", { staticClass: "x-bc h22" }, [
                          _c("div", [_vm._v("门店预定金额：")]),
                          _c("div", [
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.isBusiness
                                    ? "****"
                                    : _vm.businessData.shopReserveMoney || 0
                                )
                              ),
                            ]),
                            _vm._v("元，"),
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(
                                _vm._s(_vm.businessData.shopReserveQty || 0)
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                        _c("div", { staticClass: "x-bc h22" }, [
                          _c("div", [_vm._v("自营商城金额：")]),
                          _c("div", [
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.isBusiness
                                    ? "****"
                                    : _vm.businessData.mallMoney || 0
                                )
                              ),
                            ]),
                            _vm._v("元，"),
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(_vm._s(_vm.businessData.mallQty || 0)),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                        _c("div", { staticClass: "x-bc h22" }, [
                          _c("div", [_vm._v("美团金额：")]),
                          _c("div", [
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.isBusiness
                                    ? "****"
                                    : _vm.businessData.meituanMoney || 0
                                )
                              ),
                            ]),
                            _vm._v("元，"),
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(_vm._s(_vm.businessData.meituanQty || 0)),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                        _c("div", { staticClass: "x-bc h22" }, [
                          _c("div", [_vm._v("饿了么金额：")]),
                          _c("div", [
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.isBusiness
                                    ? "****"
                                    : _vm.businessData.areYouHungryMoney || 0
                                )
                              ),
                            ]),
                            _vm._v("元，"),
                            _c("span", { staticClass: "blueColor" }, [
                              _vm._v(
                                _vm._s(_vm.businessData.areYouHungryQty || 0)
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", {
                    staticStyle: {
                      width: "99%",
                      height: "420px",
                      "margin-top": "40px",
                    },
                    attrs: { id: "chart-businessData" },
                  }),
                ]),
              ]
            ),
            _c("div", { staticClass: "data-show x-bw" }, [
              _c(
                "div",
                {
                  staticClass: "main-item-box",
                  staticStyle: { width: "calc(50% - 4px)" },
                },
                [
                  _c("div", { staticClass: "header x-bc" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("采购数据")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "total-list x-bc" }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.purchaseData.purOrderMoney || 0) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                "采购订单(" +
                                  (_vm.purchaseData.purOrderCount || 0) +
                                  "单)"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.purchaseData.purInBillMoney || 0) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                "采购入库(" +
                                  (_vm.purchaseData.purInBillCount || 0) +
                                  "单)"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.purchaseData.purBackBillMoney || 0) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                "采购退货(" +
                                  (_vm.purchaseData.purBackBillCount || 0) +
                                  "单)"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.purchaseData.newPartnerCount || 0) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v("新增供应商(个)"),
                        ]),
                      ]),
                    ]),
                    _c("div", {
                      staticStyle: {
                        width: "99%",
                        height: "420px",
                        margin: "30px 0",
                      },
                      attrs: { id: "chart-purchase" },
                    }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "main-item-box",
                  staticStyle: { width: "calc(50% - 4px)" },
                },
                [
                  _c("div", { staticClass: "header x-bc" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("销售数据")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "total-list x-bc" }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " + _vm._s(_vm.saleData.saleOrderMoney || 0) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                "销售订单(" +
                                  (_vm.saleData.saleOrderCount || 0) +
                                  "单)"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.saleData.saleOutBillMoney || 0) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                "销售出库(" +
                                  (_vm.saleData.saleOutBillCount || 0) +
                                  "单)"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.saleData.saleBackBillMoney || 0) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                "销售退货(" +
                                  (_vm.saleData.saleBackBillCount || 0) +
                                  "单)"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.saleData.newPartnerCount || 0) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [
                          _vm._v("新增客户(个)"),
                        ]),
                      ]),
                    ]),
                    _c("div", {
                      staticStyle: {
                        width: "99%",
                        height: "420px",
                        margin: "30px 0",
                      },
                      attrs: { id: "chart-sale" },
                    }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "main-item-box",
                  staticStyle: { width: "calc(50% - 4px)" },
                },
                [
                  _c("div", { staticClass: "header x-bc" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("仓库数据")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "total-list x-bc" }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " + _vm._s(_vm.storeData.firstQty || 0) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [_vm._v("期初数")]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.storeData.inQty || 0)),
                        ]),
                        _c("div", { staticClass: "label" }, [_vm._v("收入数")]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.storeData.outQty || 0)),
                        ]),
                        _c("div", { staticClass: "label" }, [_vm._v("发出数")]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " + _vm._s(_vm.storeData.finalQty || 0) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [_vm._v("期末数")]),
                      ]),
                    ]),
                    _c("div", {
                      staticStyle: {
                        width: "99%",
                        height: "420px",
                        margin: "30px 0",
                      },
                      attrs: { id: "chart-store" },
                    }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "main-item-box",
                  staticStyle: { width: "calc(50% - 4px)" },
                },
                [
                  _c("div", { staticClass: "header x-bc" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("生产数据")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "total-list x-bc" }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " + _vm._s(_vm.produceData.orderQty || 0) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [_vm._v("订货数")]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " + _vm._s(_vm.produceData.produceQty || 0) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [_vm._v("生产数")]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " + _vm._s(_vm.produceData.inStoreQty || 0) + " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [_vm._v("入库数")]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.produceData.distributeQty || 0) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "label" }, [_vm._v("分货数")]),
                      ]),
                    ]),
                    _c("div", {
                      staticStyle: {
                        width: "99%",
                        height: "420px",
                        margin: "30px 0",
                      },
                      attrs: { id: "chart-produce" },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "aside" }, [
            _vm.tipsList.length > 0
              ? _c("div", { staticClass: "main-item-box reminderInfo" }, [
                  _c("div", { staticClass: "header x-f" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("提醒信息")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "content infoList" },
                    _vm._l(_vm.tipsList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "infoItem pointer x-b",
                          on: {
                            click: function ($event) {
                              return _vm.clickTips(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "eventName x-f" }, [
                            _c("span", { staticClass: "icon" }, [_vm._v("•")]),
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.sysRemindTypeName)),
                            ]),
                          ]),
                          _c("div", { staticClass: "num-icon x-f" }, [
                            _c("span", { staticClass: "num" }, [
                              _vm._v(_vm._s(item.count)),
                            ]),
                            _c("i", { staticClass: "el-icon-arrow-right" }),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "main-item-box officialAnnouncement" }, [
              _c("div", { staticClass: "header x-f" }, [
                _c("div", { staticClass: "title" }, [_vm._v("产品公告")]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.productLoading,
                      expression: "productLoading",
                    },
                  ],
                  staticClass: "content infoList",
                },
                _vm._l(_vm.curProductList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.noticeId,
                      staticClass: "infoItem pointer x-b",
                      on: {
                        click: function ($event) {
                          return _vm.queryNoticeDetail(item.noticeId, "0")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "eventName x-f" }, [
                        _c("span", { staticClass: "icon" }, [_vm._v("•")]),
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.noticeTitle)),
                        ]),
                      ]),
                      _c("div", { staticClass: "num-icon x-f" }, [
                        _c("i", { staticClass: "el-icon-arrow-right" }),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isProductMoreBtn,
                      expression: "isProductMoreBtn",
                    },
                  ],
                  staticClass: "moreBtn x-fc pointer",
                  on: { click: _vm.clickMoreProductNotice },
                },
                [_vm._v(" " + _vm._s(_vm.productMoreBtn) + " ")]
              ),
            ]),
            _c("div", { staticClass: "main-item-box enterpriseAnnouncement" }, [
              _c("div", { staticClass: "header x-f" }, [
                _c("div", { staticClass: "title" }, [_vm._v("企业公告")]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.enterpriseLoading,
                      expression: "enterpriseLoading",
                    },
                  ],
                  staticClass: "content infoList",
                },
                _vm._l(_vm.enterpriseNoticeList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.noticeId,
                      staticClass: "infoItem pointer x-b",
                      on: {
                        click: function ($event) {
                          return _vm.queryNoticeDetail(item.noticeId, "1")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "eventName x-f" }, [
                        _c(
                          "span",
                          {
                            staticClass: "icon",
                            staticStyle: { color: "#fdc177" },
                          },
                          [_vm._v("•")]
                        ),
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.noticeTitle)),
                        ]),
                      ]),
                      _c("div", { staticClass: "num-icon x-f" }, [
                        _c("i", { staticClass: "el-icon-arrow-right" }),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "main-item-box help" }, [
              _c("div", { staticClass: "header x-f" }, [
                _c("div", { staticClass: "title" }, [_vm._v("帮助中心")]),
              ]),
              _c(
                "div",
                { staticClass: "content" },
                _vm._l(_vm.helpList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "item pointer x-f" },
                    [
                      _c("div", { staticClass: "icon" }, [
                        _c("img", {
                          attrs: { src: item.icon, alt: "加载失败" },
                        }),
                      ]),
                      _c("div", { staticClass: "label" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "main-item-box help" }, [
              _c(
                "div",
                {
                  staticClass: "header x-f",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.clickAIDialog },
                },
                [_c("div", { staticClass: "title" }, [_vm._v("隼云AI助手")])]
              ),
            ]),
          ]),
        ]
      ),
      _c("Notice", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.curPage == "notice",
            expression: "curPage == 'notice'",
          },
        ],
        ref: "notice",
        attrs: {
          noticeId: _vm.curEnterpriseNoticeId,
          curNoticeType: _vm.curNoticeType,
        },
        on: {
          goHome: function ($event) {
            _vm.curPage = "homePage"
          },
        },
      }),
      _c("RapidInitiation", {
        attrs: {
          openDialog: _vm.openRapidInitiationDialog,
          allMenuTreeList: _vm.allMenuTreeList,
          shortcutMenuList: _vm.shortcutMenuList,
        },
        on: {
          "update:openDialog": function ($event) {
            _vm.openRapidInitiationDialog = $event
          },
          "update:open-dialog": function ($event) {
            _vm.openRapidInitiationDialog = $event
          },
          saveShortcutMenu: _vm.saveShortcutMenu,
        },
      }),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }